<template>
  <master-layout :showHeader="false" :showContentHeader="false">
    <div class="grid-content">
      <h1>Complimenti!</h1>

      <h3>Hai cambiato la tua password</h3>
      <p>Tra pochi attimi verrai reindirizzato all'app</p>
    </div>
  </master-layout>
</template>

<script>
export default {
  name: "ConfermaCambioPassword",
  mounted() {
    setTimeout(() => {
      this.$router.push("/login")
    }, 4000)
  },
}
</script>

<style scoped>
/* * {
  border: 1px solid salmon;
} */

.grid-content {
  width: 80%;
  position: absolute;
  top: calc(30%);
  left: calc(50%);
  transform: translateY(-50%) translateX(-50%);
  padding: 1rem;
  border-radius: var(--ion-border-radius);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  color: var(--text-color-light);
  background: var(--ion-color-primary);
}
</style>
